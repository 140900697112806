@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&family=Great+Vibes&display=swap');

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    /* font-family: 'Roboto, sans-serif'; */
    font-family: 'Bookman Old Style', Georgia, serif !important;
    /* background-color: #282c34; */
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    /* color: white; */
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@import url('https://fonts.googleapis.com/css?family=Josefin+Sans&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
    font-family: 'Bookman Old Style', Georgia, serif !important;
}

.wrapper {
    display: flex;
    position: relative;
    flex-direction: row;
}

.wrapper .sidebar {
    width: 270px;
    height: 100%;
    /* background: hsl(210, 14%, 91%); */
    padding: 10px 0px;
    /* box-shadow: #fff 0px 5px 15px; */
    position: fixed;
    font-family: 'Josefin Sans', sans-serif;
    min-height: 500px;
    max-height: 100vh;
    /* overflow-y: scroll; */
}

.logo {
    margin-right: 10px;
}

.line-break {
    margin: 0;
    flex-shrink: 0;
    border-width: 0;
    border-style: solid;
    /* border-color: rgba(234, 237, 241, 0.24); */
    border-bottom-width: thin;
    border-style: dashed;
}

.sidebar .row {
    width: 100%;
    height: 50px;
    list-style-type: none;
    margin: 0%;
    display: flex;
    flex-direction: row;
    /* color: rgb(9, 1, 1); */
    justify-content: flex-start;
    align-items: center;
}

.sidebar .header {
    margin: 10px 0px 0.35em;
    line-height: 1.66;
    display: block;
    color: rgb(18, 25, 38);
    font-size: 0.875rem;
    font-weight: 500;
    padding: 6px;
    text-transform: capitalize;
    /* color: #010610; */
    /* padding-top: 20px; */
    font-family: 'Roboto, sans-serif';
    fontpadding-bottom: 10px;
    padding-left: 15px;
    display: flex;
    font-size: 13px;
}

.pro-row {
    padding: 10px;
}

.sidebar .profile {
    display: flex;
    align-items: center;
    padding: 16px 20px 10px 5px;
    border-radius: 12px;
    background-color: rgb(237, 231, 246);
    transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.sidebar .row:hover {
    cursor: pointer;
    background-color: rgb(237, 231, 246);
}

.sidebar #active {
    background-color: hsl(210, 8%, 95%);
}

.row #icon {
    flex: 5%;
    display: grid;
    place-items: center;
    padding-left: 10px;
}

.row #title {
    margin: 0px;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.334em;
    font-family: 'Roboto, sans-serif';
    color: inherit;
    flex: 70%;
    font-weight: 400;
    display: flex;
    justify-content: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
}

.expandIcon {
    flex: 10%;
    /* padding: 0px !important; */
    color: 'white';
    display: flex;
    justify-content: left;
    /* margin-left: 40px !important; */
    cursor: pointer;
}

.collapse {
    /* background-color: #62839d; */
    /* padding: 10px; */
}

.wrapper .sidebar .social_media {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
}

.wrapper .sidebar .social_media a {
    display: block;
    width: 40px;
    background: #594f8d;
    height: 40px;
    line-height: 45px;
    text-align: center;
    margin: 0 5px;
    color: #bdb8d7;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.wrapper .main_content {
    width: 100%;
    margin-left: 250px;
    background-color: rgb(123, 168, 214);
}

.wrapper .main_content .header {
    font-family: 'Roboto, sans-serif';
    padding: 2px 8px 2px 10px;
    /* background: #fff;                           rgb(238, 242, 246)
    border-bottom: 1px solid #e0e4e8; */
    /* box-shadow: 0px 2px 4px -1px rgba(246, 247, 249, 0.2), 0px 4px 5px 0px rgba(145, 158, 171, 0.14), 0px 1px 10px 0px rgba(145, 158, 171, 0.12); */
}

.wrapper .main_content .info {
    margin: 5px;
    /* color: #717171; */
    line-height: 25px;
}

.wrapper .main_content .info div {
    margin-bottom: 20px;
}

@media (max-height: 500px) {
    .social_media {
        display: none !important;
    }
}


/* Scrollbar Css */

.scrollbox {
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;

    /* IE 10+ */
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(177, 205, 234, 0.09);
        border-radius: 10px;
        background-color: #F5F5F5;
    }

    ::-webkit-scrollbar {
        width: 5px !important;
        border-radius: 10px;
        background-color: 'transparent';
    }

    ::-webkit-scrollbar-thumb {
        background-color: transparent;
    }
}

.on-scrollbar {
    scrollbar-width: thin;
    /* Firefox */
    -ms-overflow-style: none;
    /* IE 10+ */
}

.on-scrollbar::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(232, 194, 234, 0.3);
    border-radius: 0px;
    background-color: #e7dde6;
    overflow-x: scroll;
}

.on-scrollbar::-webkit-scrollbar {
    width: 5px !important;
    background-color: #F5F5F5;
}

.on-scrollbar::-webkit-scrollbar-thumb {
    border-radius: 50px;
    -webkit-box-shadow: inset 0 0 6px rgba(234, 34, 228, 0.3);
    background-color: #85c1ec;
}

.outer-container {
    width: 1024px;
    /* Set the width of your outer container */
    height: 650px;
    /* Set the height of your outer container */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    /* Apply box shadow */
    overflow: hidden;
    /* Hide overflow content (e.g., shadow) outside the container */
    display: flex;
    justify-content: center;
    align-items: center;
}

.inner-container {
    width: 100%;
    /* Take the full width of the outer container */
    height: 100%;
    /* Take the full height of the outer container */
    background-size: cover;
    /* Image will be contained within the inner container */
    background-position: center;
    /* Center the background image */
    background-repeat: no-repeat;
    /* Prevent the background image from repeating */
}

.fontStyle {
    /* font-family: 'Dancing Script', cursive; */
    /* font-weight: 900; */
    font-family: 'Great Vibes', cursive;
}

/* Login and Signup CSS style */

.line-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 5px 2px;
}

div.hr-left,
div.hr-right {
    width: 40% !important;
    height: 1px;
    background-color: #cccccc;
}

p.line-text {
    font-size: 14px;
    color: #cccccc;
    width: 60%;
    margin: auto 5px;
}

.line-break {
    margin: 0;
    flex-shrink: 0;
    border-width: 0;
    border-style: solid;
    border-color: rgba(145, 158, 171, 0.24);
    border-bottom-width: thin;
    border-style: dashed;
}

.slide-show {
    width: 100%;
    height: auto;
    display: block;
}

.wrapper {
    display: flex;
    height: 11vh;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.button {
    border: 1px transparent;
    -webkit-border-radius: 40px;
    border-radius: 10px;
    color: #eeeeee;
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    padding: 8px 30px;
    text-align: center;
    text-decoration: none;
    margin-left: 20px;
    -webkit-animation: glowing 1300ms infinite;
    -moz-animation: glowing 1300ms infinite;
    -o-animation: glowing 1300ms infinite;
    animation: glowing 1300ms infinite;
}

.pdfouter-container {
    width: 800px;
    /* Set the width of your outer container */
    height: 1200px;
    /* Set the height of your outer container */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    /* Apply box shadow */
    overflow: hidden;
    /* Hide overflow content (e.g., shadow) outside the container */
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Login Style End */

.chart-container .recharts-bar-rectangle {
    border-radius: 5px;
  }

  .barStyle{
    border-radius: 10px !important;
  }