@keyframes rainbowPulse {
    0% {
        background-color: #ffffff;
    }
    25% {
        background-color: #dadada;
    }
    50% {
        background-color: #cfcfcf;
    }
    75% {
        background-color: #dadada;
    }
    100% {
        background-color: #ffffff;
    }
}

.countdown-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 92vh;
    width: 100vw;
    background-color: #f0f0f0;
    animation: rainbowPulse 3s infinite;
}

.countdown-text {
    text-align: center;
}

.countdown-value {
    font-weight: bold !important;
    font-size: 60px !important;
    font-family: sans-serif !important;
    color: rgb(13, 0, 255) !important;
}